@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.temp-class-for-bypass-jit-tailwind {
    @apply invisible visible w-[40px] opacity-0 invisible scale-0
}

body
{
    margin      : 0;
    padding     : 0;
    font-family : "Open Sans", sans-serif;
    font-size   : 14px;
}

.set-font
{
    font-family : "Open Sans", sans-serif;
}

button
{
    outline : none;
    cursor  : pointer;
}

button
{
    outline : none !important;
    cursor  : pointer;
}

button:focus
{
    outline : none !important;
}

em
{
    color      : #ef6c00;
    font-style : normal;
}

.full_width_height
{
    width  : auto;
    height : 100%;
}

.border-right-panel
{
    border-right : 1px solid #eeeeee;
}

.border-bottom
{
    border-bottom : 1px solid rgba(0, 0, 0, 0.1);
}

.flex-full-row
{
    @apply flex flex-1 h-full
}

.flex-center
{
    @apply flex justify-center items-center flex-col
}

.flex-center-col
{
    @apply flex justify-center items-center flex-col
}

.no-outline
{
    outline : 0;
}

.flex-center-row
{
    display         : flex;
    justify-content : center;
    align-items     : center;
}

.flex-start-row
{
    display         : flex;
    justify-content : flex-start;
    align-items     : center;
}


.flex-end-row
{
    display         : flex;
    justify-content : flex-end;
    align-items     : center;
}

.flex-end-col
{
    display        : flex;
    align-items    : flex-end;
    flex-direction : column;
}

.flex-start-col
{
    display        : flex;
    align-items    : center;
    flex-direction : column;
}

.flex-full
{
    position       : relative;
    display        : flex;
    flex-direction : column;
    height         : 100%;
    outline        : 0;
}

.flex-full-center
{
    display         : flex;
    justify-content : center;
    align-items     : center;
    flex-direction  : column;
    height          : 100vh;
}

.shadow
{
    box-shadow : 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition : all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.shadow1
{
    box-shadow : 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition : all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.pulse
{
    cursor     : pointer;
    box-shadow : 0 0 0 rgba(214, 3, 10, 0.4);
    animation  : pulse 2s infinite;
}

.login-Facebook
{
    width            : 350px;
    padding          : 10px 25px;
    border-radius    : 30px;
    background-color : #4267b2;
    font-size        : 20px;
    font-weight      : 500;
    color            : #ffffff;
    display          : flex;
    justify-content  : space-around;
    align-items      : center;
    border           : none;
    margin-top       : 30px;
    font-family      : "Open Sans", sans-serif;
}

@keyframes pulse
{
    0%
    {
        box-shadow : 0 0 0 0 rgba(214, 3, 10, 0.7);
    }
    70%
    {
        box-shadow : 0 0 0 13px rgba(214, 3, 10, 0);
    }
    100%
    {
        box-shadow : 0 0 0 0 rgba(214, 3, 10, 0);
    }
}

@keyframes pulse
{
    0%
    {
        -moz-box-shadow : 0 0 0 0 rgba(214, 3, 10, 0.7);
        box-shadow      : 0 0 0 0 rgba(214, 3, 10, 0.7);
    }
    70%
    {
        -moz-box-shadow : 0 0 0 13px rgba(214, 3, 10, 0);
        box-shadow      : 0 0 0 13px rgba(214, 3, 10, 0);
    }
    100%
    {
        -moz-box-shadow : 0 0 0 0 rgba(214, 3, 10, 0);
        box-shadow      : 0 0 0 0 rgba(214, 3, 10, 0);
    }
}

.cube-gradient
{
    border               : 3px solid #f45c43;
    border-left-color    : #eb3349;
    border-radius        : 3px;
    box-sizing           : border-box;
    -moz-box-sizing      : border-box;
    box-sizing           : border-box;
    /*background-position     : 0 0, 0 100%;*/
    /*background-repeat       : no-repeat;*/
    background-size      : 100% 3px;
    -moz-background-size : 100% 3px;
    background-size      : 100% 3px;
    /*background-image        : linear-gradient(left, #eb3349 0%, #f45c43 100%), linear-gradient(left, #eb3349 0%, #f45c43 100%);*/
    /*background-image        : -moz-linear-gradient(left, #eb3349 0%, #f45c43 100%), -moz-linear-gradient(left, #eb3349 0%, #f45c43 100%);*/
    /*background-image        : -o-linear-gradient(left, #eb3349 0%, #f45c43 100%), -o-linear-gradient(left, #eb3349 0%, #f45c43 100%);*/
    /*background-image        : linear-gradient(to right, #eb3349 0%, #f45c43 100%), linear-gradient(to right, #eb3349 0%, #f45c43 100%);*/
}

.header-name
{
    color           : #000;
    font-size       : 15px;
    padding         : 0px 15px;
    border-radius   : 20px;
    height          : 30px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    white-space     : nowrap;
    overflow        : hidden;
    text-overflow   : ellipsis;
}

.content
{
    display        : flex;
    flex-direction : column;
    align-items    : center;
    height         : 100%;
    overflow       : auto;
}

.content-father
{
    height   : 100%;
    overflow : auto;
    padding  : 10px 20px;
}

.imgFluid
{
    max-width : 100%;
    height    : auto;
}

.imgRounded
{
    border-radius : 6px !important;
}

.imgRoundedCircle
{
    border-radius : 50% !important;
}

.imgRaised
{
    box-shadow : 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.imgAvatar
{
    box-shadow : 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.imgProductOrder
{
    border-radius : 6px !important;
    max-width     : 80%;
    max-height    : 50px;
    height        : auto;
}

.text
{
    margin-bottom : 5px;
}

.imgSubcrip
{
    height : auto;
    width  : 140px;
}

.btn-update-fanpage
{
    margin-right        : 10px;
    appearance          : button;
    border-radius       : 30px;
    box-shadow          : 0 2px 2px 0 rgba(0, 188, 212, 0.14),
    0 3px 1px -2px rgba(0, 188, 212, 0.2),
    0 1px 5px 0 rgba(0, 188, 212, 0.12);
    background-color    : #2979ff;
    color               : #ffffff;
    cursor              : pointer;
    border              : none;
    padding             : 10px 20px;
    position            : relative;
    font-size           : 12px;
    min-width           : auto;
    text-align          : center;
    transition          : box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    min-height          : auto;
    will-change         : box-shadow, transform;
    line-height         : 1.42857143;
    white-space         : nowrap;
    font-weight         : 500;
    touch-action        : manipulation;
    text-transform      : uppercase;
    letter-spacing      : 0;
    vertical-align      : middle;
    box-sizing          : border-box;
    display             : inline-flex;
    user-select         : none;
    align-items         : center;
    justify-content     : center;
    tap-highlight-color : transparent;
    overflow            : visible;
}

.btn-update-fanpage::selection
{
    background-color : #cce2ff;
    color            : rgba(0, 0, 0, 0.87);
    cursor           : pointer;
    border-radius    : 5px;
    transition       : color 0.2s ease;
    -o-transition    : color 0.2s ease;
    transition       : color 0.2s ease;
}

.not-round
{
    border-radius : 3px;
}

/*todo payemnt*/
.payment-header
{
    font-size   : 18px;
    font-weight : bold;
    text-align  : center;
    padding     : 5px;
}

.payment-item
{
    padding   : 5px;
    font-size : larger;
}

.money_confirm
{
    font-size : 20px;
    color     : blue;
}

.bold
{
    font-weight : 700;
}

.height-header
{
    height     : 100px;
    min-height : 100px;
}

.header-action-view
{
    padding-left  : 10px;
    padding-right : 10px;
    padding-top   : 0px;
    min-height    : 50px;
}

.header-size-logo
{
    width : 80px;
}

.footer
{
    width           : 100%;
    display         : flex;
    justify-content : center;
    align-items     : center;
    text-align      : center;
    margin-top      : 20px;
}

.title
{
    font-size   : 20px;
    font-weight : 500;
}

.dialog-modal-content
{
    width : 800px;
}

.error-message
{
    display         : flex;
    color           : #f03d25;
    cursor          : pointer;
    font-size       : 12px;
    font-weight     : 400;
    margin-top      : 2px;
    padding-top     : 2px;
    position        : relative;
    text-decoration : none;
}

.error-message:hover
{
    text-decoration : underline;
    color           : #f03d25;
}

.listmessage-outer
{
    width      : 100%;
    height     : 100%;
    overflow-x : hidden;
}

.listmessage-inner
{
    overflow       : auto;
    width          : 100%;
    height         : 100%;
    display        : flex;
    flex-direction : column-reverse;
    overflow-x     : hidden;
    outline        : 0 !important;
}

.listmessage-container:hover,
.listmessage-container:active,
.listmessage-container:focus
{
    outline : 0 !important;
}

.Badget-dot
{
    top              : -5px;
    right            : -5px;
    width            : 22px;
    height           : 22px;
    display          : flex;
    z-index          : 1;
    position         : absolute;
    flex-wrap        : wrap;
    font-size        : 11px;
    align-items      : center;
    align-content    : center;
    border-radius    : 50%;
    flex-direction   : row;
    justify-content  : center;
    background-color : #f03d25;
    color            : #fff;
}

.font30
{
    font-size : 30px;
}

.overflowY
{
    overflow-y : scroll;
    overflow-x : hidden;
    padding    : 2px;
}

.border
{
    border : 1px solid #eeeeee;
}

.input-border
{
    width         : 50px;
    height        : 25px;
    font-size     : 14px;
    text-align    : center;
    margin        : 5px;
    outline       : none;
    border        : 1px solid #eeeeee;
    border-radius : 5px;
}

.vertical-line
{
    border-right : 1px #eeeeee solid;
    overflow     : auto;
    cursor       : pointer;
}

.filter-platform-item-name
{
    overflow      : hidden;
    margin-top    : 10px;
    text-align    : center;
    white-space   : nowrap;
    width         : 100px;
    text-overflow : ellipsis;
}

.icon-platform
{
    position : absolute;
    top      : 0;
    right    : 20px;
}

.hover-shadow
{
    background-color : #fff;
    transition       : all 300ms ease-in-out;
}

.hover-shadow:hover
{
    background-color : rgba(0, 0, 0, 0.08);
}

.header-trial
{
    font-size       : 14px;
    margin-right    : 10px;
    height          : 30px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    color           : #ef6c00;
    white-space     : nowrap;
    overflow        : hidden;
    text-overflow   : ellipsis;
}

.highlight-keyword
{
    background-color : #ef6c00;
    border-radius    : 5px;
    padding          : 0 4px;
    color            : #fff;
}

.highlight-active-keyword
{
    border-radius    : 5px;
    background-color : #b95d00;
    padding          : 0 4px;
    color            : #fff;
}

.sidebar-container
{
    transition : width 200ms ease-in-out;
}

.shadow3
{
    box-shadow      : 0px 8px 13px -9px rgba(0, 0, 0, 0.5);
    -moz-box-shadow : 0px 8px 13px -9px rgba(0, 0, 0, 0.5);
}

.shake
{
    display                   : block;
    position                  : relative;
    animation-name            : spaceboots;
    animation-duration        : 1.2s;
    transform-origin          : 50% 50%;
    animation-iteration-count : 5;
    animation-timing-function : linear;
}

h2.shake,
.shake.inline
{
    display : inline-block;
}

@keyframes spaceboots
{
    0%
    {
        transform : translate(2px, 1px) rotate(0deg);
    }
    10%
    {
        transform : translate(-1px, -2px) rotate(-1deg);
    }
    20%
    {
        transform : translate(-3px, 0px) rotate(1deg);
    }
    30%
    {
        transform : translate(0px, 2px) rotate(0deg);
    }
    40%
    {
        transform : translate(1px, -1px) rotate(1deg);
    }
    50%
    {
        transform : translate(-1px, 2px) rotate(-1deg);
    }
    60%
    {
        transform : translate(-3px, 1px) rotate(0deg);
    }
    70%
    {
        transform : translate(2px, 1px) rotate(-1deg);
    }
    80%
    {
        transform : translate(-1px, -1px) rotate(1deg);
    }
    90%
    {
        transform : translate(2px, 2px) rotate(0deg);
    }
    100%
    {
        transform : translate(1px, -2px) rotate(-1deg);
    }
}

.MuiChip-iconSmall
{
    color : #fff;
}

.home-message-view
{
    max-width : 700px;
    color     : #a8a8a8;
}

.reaction-container
{
    position        : absolute;
    bottom          : -15px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    background      : #fff;
    border-radius   : 12px;
    padding-left    : 5px;
    padding-right   : 5px;
    font-size       : 14px;
    height          : 20px;
    line-height     : 0;
    min-width       : 20px;
    box-shadow      : 0 2px 4px rgba(0, 0, 0, 0.15);
    color           : #626262;
    z-index:3
}

.seen-container
{
    position        : absolute;
    bottom          : -15px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    background      : #fff;
    border-radius   : 50%;
    padding         : 1px;
    line-height     : 0;
    box-shadow      : 0 2px 4px rgba(0, 0, 0, 0.15);
    color           : #626262;
    z-index: 3;
}

.mb-10
{
    margin-bottom : 10px;
}

.mb-20
{
    margin-bottom : 20px;
}

.flip
{
    -webkit-transform : scaleX(-1);
    transform         : scaleX(-1);
}

/*a:-webkit-any-link { text-decoration: none !important; }*/
.cursor-pointer
{
    cursor : pointer;
}

.visibility-hidden {
    visibility: hidden;
}

.justify-between {
    justify-content: space-between;
}

.notification-menu-read {
    opacity: 0.5;
}

.notification-menu {
    background-color: white;
    border-radius: 50%;
    padding: 4px;
    margin-left: 10px;
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.text-info {
    color: #2979FF
}

button, [role="button"] {
    cursor: auto;
    color: #2979FF;
}
